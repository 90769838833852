import React from 'react'
import SocialHeader from './../Components/Home/SocialHeader'
import Navigation from '../Components/Home/Navigation'
import ContactUsCoverSec from '../Components/ContactUs/ContactUsCoverSec'
import DetailsSec from '../Components/ContactUs/DetailsSec'
import ContactSec from '../Components/ContactUs/ContactSec'
import FooterSec from '../Components/Home/FooterSec'


const ContactUs = () => {
  return (
    <div>
      <div className='Content-wrapper'>
        <SocialHeader />
        <Navigation />
        <ContactUsCoverSec/>
        <DetailsSec/>
        <ContactSec/>
        <FooterSec />
      </div>
    </div>
  )
}

export default ContactUs