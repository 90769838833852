import React from 'react'
import SocialHeader from './../../Components/Home/SocialHeader'
import Navigation from './../../Components/Home/Navigation'
import MenuCoverSec from './../../Components/Menu Options/ManuCoverSec'
import SeachBarSec from '../../Components/Menu Options/SeachBarSec'
import FooterSec from './../../Components/Home/FooterSec'

const MenuBread = () => {
  return (
    <div>
         <div className='Content-wrapper'>
        <SocialHeader />
        <Navigation />
        <MenuCoverSec/>
        <SeachBarSec/>
        <FooterSec />
      </div>
    </div>
  )
}

export default MenuBread