import React from 'react'
import { NavLink } from 'react-router-dom'

const ContactSec = () => {

    return (
        <div className='contactus-sec'>
            <div className='map-sec'>
                <div className='map-outer'>
                    <div className='gmap-canvas'>
                        <iframe src="https://maps.google.com/maps?q=Delhi%20Deli%20Contemporary%20Cafe%20312/2%20East,%20Shwegondaing%20Rd,%20Yangon,%20Myanmar%20(Burma)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" id="gmap_canvas" frameborder="0"  title='.'></iframe>
                        <NavLink href="https://fnftest.net/fnf-test-download/" style={{display:'none'}}>
                            fnf test download
                        </NavLink>
                        <NavLink href="https://www.jixplay.com/cuphead/" style={{display:'none'}}>
                            cuphead
                        </NavLink>
                        <NavLink href="https://fnfmod.net" style={{display:'none'}}>
                            fnf mods 
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='form-sec'>
                <div className='form-head'>
                    <h1>send us a message</h1>
                </div>
                <div className='form-container'>
                    <div className='form-input'>
                        <input type='text' placeholder='Your Name' className='input-box' />
                    </div>
                    <div className='form-input'>
                        <input type='text' placeholder='Email' className='input-box' />
                    </div>
                    <div className='form-input'>
                        <input type='text' placeholder='Subject' className='input-box' />
                    </div>
                    <div className='form-input-msg'>
                        <textarea type='text' placeholder='Comment' className='input-msg-box' />
                    </div>
                    <button type='submit' className='btn' style={{ textTransform: 'uppercase' }}>submit</button>
                </div>
            </div>
        </div>
    )
}

export default ContactSec

