import React from 'react'

const AboutSec1 = () => {
    return (
        <div className='about-sec-1'>
            <div className='sec1-text'>
                <div className='wlcm-text'>
                    <span>Welcome!</span>
                </div>
                <div className='sec-head'>
                    <span>A caring team is ready to serve you love everyday.</span>
                </div>
                <div className='sec-text'>
                    <p>Opened recently in Yangon, Myanmar, Delhi Deli is the ideal place to experience a  delicious blend of traditional and contemporary Indian cuisine in a comfortable, pleasant, and warm environment. Delhi Deli has a special talent to change people’s perceptions of Indian cuisine.</p>
                </div>
                <button type='submit' className='btn'>Contact Us</button>
            </div>
            <div className='sec1-img'>
                <div className='img-container-1'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-6-2.png?w=200&ssl=1' alt='.' className='img-1' />
                </div>
                <div className='img-container-2'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-1-3-1.png?w=391&ssl=1' alt='.' className='img-2' />
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-2-1-2.png?w=280&ssl=1' alt='.' className='img-3' />
                </div>
            </div>
        </div>
    )
}

export default AboutSec1