import React from 'react'
import SocialHeader from './../Components/Home/SocialHeader'
import Navigation from '../Components/Home/Navigation'
import AboutUsCover from '../Components/AboutUs/AboutUsCover'
import AboutSec1 from '../Components/AboutUs/AboutSec1'
import PromiseSec from '../Components/AboutUs/PromiseSec'
import GoalSec from '../Components/AboutUs/GoalSec'
import ClientReviews from '../Components/AboutUs/ClientReviews'
import OrderSec from '../Components/AboutUs/OrderSec'
import FooterSec from '../Components/Home/FooterSec'

const AboutUs = () => {
  return (
    <div>
      <div className='Content-wrapper'>
        <SocialHeader />
        <Navigation />
        <AboutUsCover/>
        <AboutSec1/>
        <PromiseSec/>
        <GoalSec/>
        <ClientReviews/>
        <OrderSec/>
        <FooterSec/>
      </div>
    </div>
  )
}

export default AboutUs