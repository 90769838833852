import React, { useState } from 'react'
import { FaBars } from "react-icons/fa";
import { VscChromeClose } from 'react-icons/vsc'
import { MdAddIcCall } from 'react-icons/md'
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowUp } from "react-icons/md"

const MobileNav = () => {

    const [showMenu, setShowMenu] = useState(false)
    const [showSubMenu, setShowSubMenu] = useState(false)

    const openMenu= () => {
        setShowMenu(true);
        document.body.classList.add("demo")
    }
    const closeMenu= () => {
        setShowMenu(false);
        document.body.classList.remove("demo")
    }


    console.log(showSubMenu)

    let navmenu

    if (showMenu) {
        navmenu =
            <div className='nav-menu-mobile'>
                <div className='nav-close-btn'>
                    <VscChromeClose onClick={closeMenu} />
                </div>
                <div className='menu-items-mobile'>
                    <div className='menu-link'>
                        <NavLink to='/'>Home</NavLink>
                    </div>
                    <div className='menu-link'>
                        <NavLink to='/about'>about</NavLink>
                    </div>
                    <div className='menu-link'>
                        <div className='dropbtn-mobile' onClick={() => setShowSubMenu(!showSubMenu)}>
                            <NavLink to='/menu'>menu</NavLink>
                            <MdKeyboardArrowUp className={showSubMenu ? 'menu-toggle-open' : ""} />
                        </div>
                        <div className={showSubMenu ? 'dropdown-mobile-open' : 'dropdown-mobile-close'}>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/breads'>Breads</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/combo-sets'>Combo sets</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/drinks'>drinks</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/desserts'>desserts</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/ice-cream'>ice cream</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/kulfi'>kulfi</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/non=veg'>non veg bites</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/shahi-lunch'>shahi lunch</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/south-tiffin-box'>south tiffin box</NavLink>
                            </div>
                            <div className='sub-nav-menu'>
                                <NavLink to='/menu/veg-bites'>veg bites</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='menu-link'>
                        <NavLink to='/blog'>blogs</NavLink>
                    </div>
                    <div className='menu-link'>
                        <NavLink to='/contact'>contact</NavLink>
                    </div>
                </div>
            </div>
    }

    return (
        <header className='mobile-nav'>
            <div className='nav-btn'>
                <FaBars onClick={openMenu} />
                {navmenu}
            </div>
            <div className='logo'>
                {/* <img src='https://delhidelicafe.com/wp-content/uploads/2021/06/Delhi-Deli-Dot-1.2-1.svg' alt='.' /> */}
                <h1 style={{color:'#f30'}}>Delight</h1>
            </div>
            <div className='call-opt'>
                <MdAddIcCall />
            </div>
        </header>
    )
}

export default MobileNav