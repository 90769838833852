import React from 'react'
import { BiCartAlt } from "react-icons/bi";

const ItemListView = () => {
    return (
        <div className='list-view'>
            <div className='itm-container'>
                <div className='itm-img'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/naan1-1.png?w=600&ssl=1' alt='.' />
                </div>
                <div className='itm-info'>
                    <span className='itm-name'>Lorem ipsum dolor sit </span> <br />
                    <span className='itm-desc'>Lorem ipsum dolor sit amet consectetur Itaque exercitationem </span><br />
                    <span className='itm-price'>MMK 18,000</span><br />
                </div>
                <div className='cart-icon'>
                    <span><BiCartAlt /></span>
                </div>
            </div>
            <div className='itm-container'>
                <div className='itm-img'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/naan1-1.png?w=600&ssl=1' alt='.' />
                </div>
                <div className='itm-info'>
                    <span className='itm-name'>Lorem ipsum dolor sit </span> <br />
                    <span className='itm-desc'>Lorem ipsum dolor sit amet consectetur Itaque exercitationem </span><br />
                    <span className='itm-price'>MMK 18,000</span><br />
                </div>
                <div className='cart-icon'>
                    <span><BiCartAlt /></span>
                </div>
            </div>
            <div className='itm-container'>
                <div className='itm-img'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/naan1-1.png?w=600&ssl=1' alt='.' />
                </div>
                <div className='itm-info'>
                    <span className='itm-name'>Lorem ipsum dolor sit </span> <br />
                    <span className='itm-desc'>Lorem ipsum dolor sit amet consectetur Itaque exercitationem </span><br />
                    <span className='itm-price'>MMK 18,000</span><br />
                </div>
                <div className='cart-icon'>
                    <span><BiCartAlt /></span>
                </div>
            </div>
            <div className='itm-container'>
                <div className='itm-img'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/naan1-1.png?w=600&ssl=1' alt='.' />
                </div>
                <div className='itm-info'>
                    <span className='itm-name'>Lorem ipsum dolor sit </span> <br />
                    <span className='itm-desc'>Lorem ipsum dolor sit amet consectetur Itaque exercitationem </span><br />
                    <span className='itm-price'>MMK 18,000</span><br />
                </div>
                <div className='cart-icon'>
                    <span><BiCartAlt /></span>
                </div>
            </div>
            <div className='itm-container'>
                <div className='itm-img'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/naan1-1.png?w=600&ssl=1' alt='.' />
                </div>
                <div className='itm-info'>
                    <span className='itm-name'>Lorem ipsum dolor sit </span> <br />
                    <span className='itm-desc'>Lorem ipsum dolor sit amet consectetur Itaque exercitationem </span><br />
                    <span className='itm-price'>MMK 18,000</span><br />
                </div>
                <div className='cart-icon'>
                    <span><BiCartAlt /></span>
                </div>
            </div>
        </div>
    )
}

export default ItemListView