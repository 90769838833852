import React from 'react'
import { VscChevronRight } from "react-icons/vsc";
import { NavLink } from 'react-router-dom'

const ManuCoverSec = () => {
  return (
    <div className='menu-cover-sec'>
    <div className='menu-cover-text'>
      <h2>lorem ipsum</h2>
      <span>
        <NavLink to='/'>Home</NavLink> <VscChevronRight /> this page
      </span>
    </div>
  </div>
  )
}

export default ManuCoverSec