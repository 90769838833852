import React from 'react'
// import { gsap } from "gsap";

// gsap.registerPlugin(ScrollTrigger);


const PromiseSec = () => {
  return (
    <div className='promise-content'>
      <div className='promise-text'>
        <div className='cursive-text'>
          <span>Our Quality</span>
        </div>
        <div className='head-sec'>
          <span>Promise</span>
        </div>
        <div className='sec-text'>
          <p>Opened recently in Yangon, Myanmar, Delhi Deli is the ideal place to experience a  delicious blend of traditional and contemporary Indian cuisine in a comfortable, pleasant, and warm environment. Delhi Deli has a special talent to change people’s perceptions of Indian cuisine.</p>
        </div>
        <button type='submit' className='btn' style={{textTransform:'uppercase'}}>order now</button>
      </div>
      <div className='promise-img'>
          <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-3-1-2.png?w=721&ssl=1' alt='.'/>
      </div>
    </div>
  )
}

export default PromiseSec

// Delight