import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LoremIpsum } from 'react-lorem-ipsum';

const ClientReviews = () => {
    const settings = {
        autoPlay: true,
        arrows: false,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
  return (
    <div className='client-reviews' style={{backgroundColor:'#fff'}}>
        <h4>What Our Client Says</h4>
        <Slider {...settings}>
            <div className='reviews'>
                <div className='reviews-content'>
                    <p><LoremIpsum avgSentencesPerParagraph={5}/></p>
                    <h3>Than Than Soe</h3>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-content'>
                    <p><LoremIpsum avgSentencesPerParagraph={5}/></p>
                    <h3>Than Than Soe</h3>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-content'>
                    <p><LoremIpsum avgSentencesPerParagraph={5}/></p>
                    <h3>Than Than Soe</h3>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-content'>
                    <p><LoremIpsum avgSentencesPerParagraph={5}/></p>
                    <h3>Than Than Soe</h3>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-content'>
                    <p><LoremIpsum avgSentencesPerParagraph={5}/></p>
                    <h3>Than Than Soe</h3>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-content'>
                    <p><LoremIpsum avgSentencesPerParagraph={5}/></p>
                    <h3>Than Than Soe</h3>
                </div>
            </div>
        </Slider>
    </div>
  )
}

export default ClientReviews