import React , {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { MdOutlineDeliveryDining ,MdKeyboardArrowDown } from "react-icons/md"


const DesktopNav = () => {

    const [hover , sethover] = useState(false)

    const hoverActive = () => {
        sethover(true)
    }

    const hoverClose = () => {
        sethover(false)
    }

    return (
        <div className='DesktopNav'>
            <div className='desk-logo'>
                <h1 style={{color:'#f30'}}>Delight</h1>
            </div>
            <div className='desk-nav'>
                <div className='nav-menu'>
                    <NavLink to='/'>Home</NavLink>
                </div>
                <div className='nav-menu'>
                    <NavLink to='/about'>About</NavLink>
                </div>
                <div className='nav-menu'>
                    <NavLink to='/menu' className='dropbtn' onMouseEnter={hoverActive} onMouseLeave={hoverClose}>Menu <MdKeyboardArrowDown/> </NavLink>
                    <ul className={`dropdown-content ${hover ? 'dropdown-content-active' : ' '}`} onMouseEnter={hoverActive} onMouseLeave={hoverClose} >
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/breads'>breads</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/combo-sets'>Combo Sets</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/drinks'>Drinks</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/desserts'>desserts</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink>ice cream</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/kulfi'>kulfi</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/non=veg'>non veg bites</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/shahi-lunch'>shahi lunch</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/south-tiffin-box'>south tiffin box</NavLink>
                        </li>
                        <li className='sub-nav-menu'>
                            <NavLink to='/menu/veg-bites'>veg bites</NavLink>
                        </li>
                    </ul>
                </div>
                <div className='nav-menu'>
                    <NavLink to='/blog'>Blog</NavLink>
                </div>
                <div className='nav-menu'>
                    <NavLink to='/contact'>Contact</NavLink>
                </div>
            </div>
            <div className='desk-call'>
                <div className='call-icon'>
                    <MdOutlineDeliveryDining/>
                </div>
                <div className='call-text'>
                    <h3>Call and order on</h3>
                    <h2>(+959) 969684514</h2>
                </div>
            </div>
        </div>
    )
}

export default DesktopNav