import React from 'react'
import { NavLink } from 'react-router-dom'
import { VscChevronRight } from "react-icons/vsc";

const MenuCoverSec = () => {
  return (
        <div className='contact-cover'>
      <div className='contact-text'>
        <h2>Shop</h2>
        <span>
          <NavLink to='/'>Home</NavLink> <VscChevronRight /> Shop
        </span>
      </div>
    </div>
  )
}

export default MenuCoverSec