import React from 'react'

const GoalSec = () => {
    return (
        <div className='goal-content'>
            <div className='goal-img'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-4-2-1.png?w=477&ssl=1' alt='.' />
            </div>
            <div className='goal-text'>
                <div className='cursive-text'>
                    <span>Our</span>
                </div>
                <div className='head-sec'>
                    <span>Goal</span>
                </div>
                <div className='sec-text'>
                    <p>Opened recently in Yangon, Myanmar, Delhi Deli is the ideal place to experience a  delicious blend of traditional and contemporary Indian cuisine in a comfortable, pleasant, and warm environment. Delhi Deli has a special talent to change people’s perceptions of Indian cuisine.</p>
                </div>
                <button type='submit' className='btn' style={{textTransform:'uppercase'}}>order now</button>
            </div>
        </div>
    )
}

export default GoalSec