import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { NavLink } from 'react-router-dom';
// import { createTheme } from '@mui/material/styles';




export default function CardItem() {

  // const [favItem , setFavItem] = useState(false);

  const addToFavItem = () => {
   
      
  }

  return (
    <div className='card-container'>
      <div className='item-card'>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
        <div className='card'>
          <NavLink>
            <Card sx={{ maxWidth: 250 }}>
              <CardMedia
                component="img"
                height="194"
                image="https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Spicy-Raw-Mango-1.png?fit=600%2C600&ssl=1"
                alt="Paella dish"
                className='item-img'
              />
              <CardContent>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  This impressive paella is a perfect
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" onClick={()=> addToFavItem
                }>
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </NavLink>
        </div>
      </div>
      <div className='product-btn'>
        <button className='pro-btn'>All Products</button>
      </div>
    </div>
  );
}