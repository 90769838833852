import React from 'react'
import { VscChevronRight } from "react-icons/vsc";
import { NavLink } from 'react-router-dom'

const ContactUsCoverSec = () => {
  return (
    <div className='contact-cover'>
      <div className='contact-text'>
        <h2>Contact Us</h2>
        <span>
          <NavLink to='/'>Home</NavLink> <VscChevronRight /> About Us
        </span>
      </div>
    </div>
  )
}

export default ContactUsCoverSec