import React from 'react'
import { useState, useEffect } from 'react';
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'

const Navigation = () => {
    let [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
    });
  return (
    <div className="navigation">
    { width <= 1080 ? <MobileNav/>  : <DesktopNav/> }
</div>
  )
}

export default Navigation