import React from 'react'
import LoremIpsum from 'react-lorem-ipsum'

const Promises = () => {
    return (
        <div className='promise-container'>
            <div className='promise-head'>
                <span>Our Promises</span>
            </div>
            <div className='promises'>
                <div className='cards'>
                    <div className='card-img'>
                        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-6-1-1.png?fit=600%2C600&ssl=1' alt='.' />
                    </div>
                    <div className='card-head'>
                        <span>We bake with love just for you.</span>
                    </div>
                    <div className='card-text'>
                        <LoremIpsum avgSentencesPerParagraph={2} />
                    </div>
                </div>
                <div className='cards'>
                    <div className='card-img'>
                        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-3-2.png?fit=600%2C600&ssl=1' alt='.' />
                    </div>
                    <div className='card-head'>
                        <span>We bake with love just for you.</span>
                    </div>
                    <div className='card-text'>
                        <LoremIpsum avgSentencesPerParagraph={1} />
                    </div>
                </div>
                <div className='cards'>
                    <div className='card-img'>
                        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-4-1-1.png?fit=600%2C600&ssl=1' alt='.' />
                    </div>
                    <div className='card-head'>
                        <span>We bake with love just for you.</span>
                    </div>
                    <div className='card-text'>
                        <LoremIpsum avgSentencesPerParagraph={1} />
                    </div>
                </div>
                <div className='cards'>
                    <div className='card-img'>
                        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-5-2.png?fit=600%2C600&ssl=1' alt='.' />
                    </div>
                    <div className='card-head'>
                        <span>We bake with love just for you.</span>
                    </div>
                    <div className='card-text'>
                        <LoremIpsum avgSentencesPerParagraph={2} />
                    </div>
                </div>
                <div className='cards'>
                    <div className='card-img'>
                        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-7-1-1.png?fit=600%2C600&ssl=1' alt='.' />
                    </div>
                    <div className='card-head'>
                        <span>We bake with love just for you.</span>
                    </div>
                    <div className='card-text'>
                        <LoremIpsum avgSentencesPerParagraph={1} />
                    </div>
                </div>
                <div className='cards'>
                    <div className='card-img'>
                        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-2-2-1.png?fit=600%2C600&ssl=1' alt='.' />
                    </div>
                    <div className='card-head'>
                        <span>We bake with love just for you.</span>
                    </div>
                    <div className='card-text'>
                        <LoremIpsum avgSentencesPerParagraph={1} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promises


