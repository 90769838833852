import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuBtn = () => {
    return (
        <div className='menu-btn-sec'>
            <h1 className='menu-head'>take a look at our menu</h1>
            <div className='item-menu'>
                <div className='item-btn'>
                    <NavLink>ice cream</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink>veg bites</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink>non-veg bites</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink >south tiffin box</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink>shahi lunch</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink>combo sets</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink>breads</NavLink>
                </div>
                <div className='item-btn'>
                    <NavLink>drinks</NavLink>
                </div>
            </div>
        </div>
    )
}

export default MenuBtn
