import React , {useState} from 'react'
import { FaThList } from "react-icons/fa"
import { BsFillGridFill } from "react-icons/bs";
import ItemListView from './ItemListView';
import ItemGridView from './ItemGridView';

const SeachBarSec = () => {

    // eslint-disable-next-line no-unused-vars
    const [showInGridView, setGridView] = useState(false)
    const [showInListView, setLisView] = useState(true)

    const openGridView = () => {
        setGridView(true)
        setLisView(false)
    }
    
    const openListView = () => {
        setLisView(true)
        setGridView(false)
    }

    return (
        <div className='menu-sec2'>
            <div className='bar-sec'>
                <div className='result-text'>
                    <span style={{ opacity: '0.5' }}>Showing all 2 results</span>
                </div>
                <div className='select-option'>
                    <button type='button' className='grid-btn' onClick={openGridView}><BsFillGridFill /></button>
                    <button type='button' className='view-btn' onClick={openListView}><FaThList /></button>
                    <select name='mySort' id='sorting'>
                        <option value='Default Sorting'>Default Sorting</option>
                        <option value='Default Sorting'>Sort by Popularity</option>
                        <option value='Default Sorting'>Sort by average rating</option>
                        <option value='Default Sorting'>Sort by latest</option>
                        <option value='Default Sorting'>Sort by Price: low to high</option>
                        <option value='Default Sorting'>Sort by Price: high to low</option>
                    </select>
                </div>
            </div>

            <div className='menu-items-view'>
                {showInListView ?  <ItemListView/> : <ItemGridView/>}
            </div>
        </div>
    )
}

export default SeachBarSec