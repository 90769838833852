import React from 'react'

const CoverOrder = () => {
  return (
    <div className='cover-order'>
       <h1  className='cover-logo'>Delight</h1>
       <h2 className='cover-text'>Lorem ipsum dolor sit amet consectetur </h2>
       <button className='order-btn'>order now</button>
    </div>
  )
}

export default CoverOrder