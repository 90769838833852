import React from 'react'
import SocialHeader from './../Components/Home/SocialHeader'
import Navigation from '../Components/Home/Navigation'
import MenuCoverSec from '../Components/Menu/MenuCoverSec'
import MenuOption from '../Components/Menu/MenuOption'
import FooterSec from '../Components/Home/FooterSec'


const Menu = () => {
  return (
    <div>
      <div className='Content-wrapper'>
        <SocialHeader />
        <Navigation />
        <MenuCoverSec />
        <MenuOption/>
        <FooterSec />
      </div>
    </div>
  )
}

export default Menu