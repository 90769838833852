import React from 'react'
import SocialHeader from './../Components/Home/SocialHeader'
import Navigation from '../Components/Home/Navigation'
import HeroSection from './../Components/Home/Hero'
import MenuSec from './../Components/Home/MenuSec'
import CoverOrder from './../Components/Home/CoverOrder'
import MenuBtn from './../Components/Home/MenuBtn'
import CardItem from './../Components/Home/CardItem'
import Promises from '../Components/Home/Promises'
import Sellers from '../Components/Home/Sellers'
import ContactFrom from '../Components/Home/ContactFrom'
import ClientReviews from '../Components/Home/ClientReviews'
import FooterSec from '../Components/Home/FooterSec'


const Home = () => {
  return (
    <div>
      <div className='Content-wrapper'>
        <SocialHeader />
        <Navigation />
        <HeroSection />
        <MenuSec />
        <CoverOrder />
        <MenuBtn />
        <CardItem />
        <Promises />
        <Sellers />
        <ContactFrom />
        <ClientReviews />
        <FooterSec />
      </div>
    </div>
  )
}

export default Home