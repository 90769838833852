import React from 'react'

const MenuSec = () => {
  return (
    <div className='menu-sec'>
        <div className='button-container'>
            <button type='submit' className='menu-btn'>Menu</button>
        </div>
        <div className='menu-items'>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/06/Rectangle-4-1-1.png?resize=100%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-9.png?fit=121%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-1-4.png?fit=121%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/06/Rectangle-6-1.png?fit=121%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-23-1.png?fit=121%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-2-3.png?fit=121%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/06/6-1-1.jpg?fit=121%2C81&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
            <div className='item' id='drink'>
                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/drink-1.png?w=980&ssl=1' alt='.'/>
                <h3>lorem ipsum</h3>
            </div>
        </div>
    </div>
  )
}

export default MenuSec