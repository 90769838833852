import React from 'react'
import { BiMobileAlt } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineLocationOn, MdOutlineEmail, MdCall } from "react-icons/md";

const DetailsSec = () => {
  return (
    <div className='details-sec'>
        <div className='detail-head'>
            <h1>Call Us or Visit Place</h1>
        </div>
        <div className='contact-options'>
            <div className='calling-details'>
                <div className='icon'>
                    <BiMobileAlt/>
                </div>
                <div className='call-no'>
                    <p><BsWhatsapp className='s-icon'/> (+959) 969684514</p>
                    <p><MdCall className='s-icon'/>(+959) 969684521</p>
                </div>
            </div>
            <div className='address-details'>
                <div className='icon'>
                    <MdOutlineLocationOn/>
                </div>
                <div className='address'>
                    <h2>Address</h2>
                    <p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
                </div>
            </div>
            <div className='email-details'>
                <div className='icon'>
                    <MdOutlineEmail/>
                </div>
                <div className='email'>
                    <h2>email :</h2>
                    <p>example@cafe.com</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailsSec