import React from 'react'
import { BsFacebook, BsInstagram } from "react-icons/bs";
import {NavLink} from "react-router-dom"

const FooterSec = () => {
    return (
        <div className='footer-sec'>
            <div className='ftr-logo'>
                {/* <img src='https://delhidelicafe.com/wp-content/uploads/2021/06/Delhi-Deli-Dot-1.2-1.svg' alt='' /> */}
                <h1>Delight</h1>
            </div>
            <div className='details-container'>
                <div className='add-sec'>
                    <h2>ADDRESS</h2>
                    <p>312/A East Shwegone Daing Road, Near SSC Hospital, Bahan Township, Yangon, Myanmar.</p>
                </div>
                <div className='contact-sec'>
                    <h2>CONTACT DETAILS</h2>
                    <p>(+959) 969684514</p>
                    <p>breaktime@delhidelicafe.com</p>
                </div>
                <div className='time-sec'>
                    <h2>OPENING HOURS</h2>
                    <p>Monday – Sunday: 8am – 8pm</p>
                    <NavLink>
                        <BsFacebook className='icons' />
                    </NavLink>
                    <NavLink>
                        <BsInstagram className='icons' />
                    </NavLink>
                </div>
            </div>
            <div className='footer-bottom'>
                <p>
                Copyright © 2021 <NavLink>Delhi Deli</NavLink>. All Rights Reserved. Designed and Developed by <NavLink> Cyber Dolphins Pte. Ltd.</NavLink>
                </p>
            </div>
        </div>
    )
}

export default FooterSec