import React from 'react'

const OrderSec = () => {
  return (
    <div className='order-sec'>
        <div className='cursive-text'>
            <span>We deliver</span>
        </div>
        <div className='head-sec'>
            <span>Food at your Doorstep!</span>
        </div>
        <div className='sec-text'>
            <p>
            Order from our exquisite menu and enjoy the food at the comfort of your home.
            </p>
        </div>
        <button type='submit' className='btn' style={{textTransform:'uppercase'}}>Order Now</button>
    </div>
  )
}

export default OrderSec 