import React from 'react'
import { FaChevronCircleRight } from "react-icons/fa";
import { NavLink } from 'react-router-dom'

const Sellers = () => {
    return (
        <div className='sellers-container'>
            <div className='slr-head'>
                <h1>Lorem ipsum</h1>
                <button className='see-all-btn'>see all &ensp;<FaChevronCircleRight className='right-arrow' /></button>
            </div>
            <div className='seller-cards'>
                <NavLink>
                    <div className='item-cards'>
                        <div className='i-card'>
                            <div className='item-img'>
                                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/momo-1.png?w=600&ssl=1' alt='.' />
                            </div>
                            <div className='item-desc'>
                                <p className='item-name'>lorem ipsum omet</p>
                                <p className='item-vn'>lorem ipsum omet</p>
                                <p className='item-price'>lorem ipsum 4234</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink>
                    <div className='item-cards'>
                        <div className='i-card'>
                            <div className='item-img'>
                                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/momo-1.png?w=600&ssl=1' alt='.' />
                            </div>
                            <div className='item-desc'>
                                <p className='item-name'>lorem ipsum omet</p>
                                <p className='item-vn'>lorem ipsum omet</p>
                                <p className='item-price'>lorem ipsum 4234</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink>
                    <div className='item-cards'>
                        <div className='i-card'>
                            <div className='item-img'>
                                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/momo-1.png?w=600&ssl=1' alt='.' />
                            </div>
                            <div className='item-desc'>
                                <p className='item-name'>lorem ipsum omet</p>
                                <p className='item-vn'>lorem ipsum omet</p>
                                <p className='item-price'>lorem ipsum 4234</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink>
                    <div className='item-cards'>
                        <div className='i-card'>
                            <div className='item-img'>
                                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/momo-1.png?w=600&ssl=1' alt='.' />
                            </div>
                            <div className='item-desc'>
                                <p className='item-name'>lorem ipsum omet</p>
                                <p className='item-vn'>lorem ipsum omet</p>
                                <p className='item-price'>lorem ipsum 4234</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink>
                    <div className='item-cards'>
                        <div className='i-card'>
                            <div className='item-img'>
                                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/momo-1.png?w=600&ssl=1' alt='.' />
                            </div>
                            <div className='item-desc'>
                                <p className='item-name'>lorem ipsum omet</p>
                                <p className='item-vn'>lorem ipsum omet</p>
                                <p className='item-price'>lorem ipsum 4234</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink>
                    <div className='item-cards'>
                        <div className='i-card'>
                            <div className='item-img'>
                                <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/momo-1.png?w=600&ssl=1' alt='.' />
                            </div>
                            <div className='item-desc'>
                                <p className='item-name'>lorem ipsum omet</p>
                                <p className='item-vn'>lorem ipsum omet</p>
                                <p className='item-price'>lorem ipsum 4234</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
                

            </div>
        </div>
    )
}

export default Sellers