import React from 'react'

const Hero = () => {
  return (
    <div className='hero-cover'>
      <div className='hero-text'>
        <span id='t1'>Lorem Ipsum</span>
        <span id='t2'>Lorem </span>
        <span id='t3'>Lorem ipsum dolor sit amet consectetur</span>
        <span id='t4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque exercitationem mollitia explicabo fugit consequatur optio eveniet esse</span>
        <button type='submit' className='odr-btn'>order now</button>
      </div>
      <div className='hero-img'>
        <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/06/700-1.png?w=700&ssl=1' alt='.' />
      </div>
    </div>
  )
}

export default Hero