import './App.css';
import {Routes , Route} from 'react-router-dom'
import Home from './Pages/Home'
import AboutUs  from './Pages/AboutUs'
import ContactUs  from './Pages/ContactUs'
import Menu from './Pages/Menu'
import MenuBread from './Pages/Menu Pages/MenuBread'
import ComboSets from './Pages/Menu Pages/MenuBread'



function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}  />
      <Route path='/about' element={<AboutUs/>}  />
      <Route path='/contact' element={<ContactUs/>}  />
      <Route path='/menu' element={<Menu/>}  />
      <Route path='/menu/breads' element={<MenuBread/>}  />
      <Route path='/menu/combo-sets' element={<ComboSets/>}  />
    </Routes>
  );
}

export default App;
