import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuOption = () => {
    return (
        <div className='menu-options'>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/veg-bites-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/South-indian-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/shahi-lunch-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/non-veg-bites-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-8-2.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Untitled-design-2-1-1-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/drink-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/mithai-1-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Combo-sets-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
            <NavLink to='/menu-veg-bites'>
                <div className='option-container'>
                    <img src='https://i0.wp.com/delhidelicafe.com/wp-content/uploads/2021/09/Breads-1.png?resize=450%2C450&ssl=1' alt='.' />
                    <h3>Veg Bites <span>(10)</span></h3>
                </div>
            </NavLink>
        </div>
    )
}

export default MenuOption