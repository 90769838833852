import React from 'react'

const ContactFrom = () => {
  return (
    <div className='contact'>
        <div className='contact-form-1'>
            <div className='form-head'>
                <h3>Contact Us</h3>
            </div>
            <div className='form-container-1'>
                <input type='text' className='input-group-1' placeholder='Name'/>
                <input type='email' className='input-group-1' placeholder='Email'/>
                <input type='number' className='input-group-1' placeholder='Contact Number'/>
                <input type='text' className='input-group-1' placeholder='Subject'/>
            </div>
            <textarea type='text' placeholder='Message' className='input-msg'/>
            <button type='submit' className='send-btn'>SEND</button>
        </div>
        <div className='res-img'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU6d6prLSLG_dJ3_F-V0Uu_77r0bhzKwB_3Q&usqp=CAU' alt='.'/>
        </div>
    </div>
  )
}

export default ContactFrom