import React from 'react'
import { NavLink } from 'react-router-dom'
import { VscChevronRight } from "react-icons/vsc";

const AboutUsCover = () => {
    return (
        <div className='about-cover'>
            <div className='about-text'>
                <h2>About Us</h2>
                <span>
                    <NavLink to='/'>Home</NavLink> <VscChevronRight/> About Us
                </span>
            </div>
        </div>
    )
}

export default AboutUsCover