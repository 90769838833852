import React from "react";
import { BsFacebook ,BsTelephoneFill ,BsInstagram ,BsGeoAltFill } from "react-icons/bs";

const SocialHeader = () => {
    return (
        <div className="header">
            <div className="contact-info">
                <div className="call-info">
                    <BsTelephoneFill/> &ensp;
                    <span>Call Us: (+959)969684514</span>
                </div>
                <div className="address-info">
                    <BsGeoAltFill/> &ensp;
                    <span>
                        321/A East Shwegon Daing Road, Near SSC Hospital, Bahan Township,
                        Yangon, Myanmar.
                    </span>
                </div>
            </div>
            <div className="social-media">
                <BsFacebook className="social-icon"/>
                <BsInstagram className="social-icon"/>
            </div>
        </div>
    );
};

export default SocialHeader;


